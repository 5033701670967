export const light_grey = '#F5F5F5';
export const dark_grey = '#353535';
export const orange = '#F2784B';

export const mobile_width = '767px';
export const mobile_width_strict = '480px';
export const desktop_width = '989px';

export default {
  light_grey,
  dark_grey,
  orange,
  mobile_width,
  mobile_width_strict,
  desktop_width
}
